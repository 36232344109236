import { useMutation } from '@tanstack/react-query';
import type { NewsletterSignupProps } from '@we-make-websites/ui-lib';
import { NewsletterSignup } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import { DOB_SUPPORTED_LOCALES, storeLocale } from '@/root/constants';
import type { NewsletterVariables } from 'utils/newsletter';
import { newsletterFetcher } from 'utils/newsletter';

export const NewsletterSignupConnected = (props: NewsletterSignupProps) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const showDobField =
    DOB_SUPPORTED_LOCALES && DOB_SUPPORTED_LOCALES.includes(locale);

  const newsletterMutation = useMutation(newsletterFetcher);

  const onSubmit = async (payload: NewsletterVariables) => {
    return await newsletterMutation.mutateAsync({
      ...payload,
      locale,
    });
  };

  return (
    <NewsletterSignup
      {...props}
      showDobField={showDobField}
      onSubmit={onSubmit}
    />
  );
};
