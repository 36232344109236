import { i18n } from '@lingui/core';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useContext } from 'use-context-selector';
import { OverlayContext } from 'components/overlay/OverlayContext';
import IconCH from 'icons/misc/ch.svg';
import IconDE from 'icons/misc/de.svg';
import IconES from 'icons/misc/es.svg';
import IconFR from 'icons/misc/fr.svg';
import IconGB from 'icons/misc/gb.svg';
import IconIT from 'icons/misc/it.svg';
import IconJP from 'icons/misc/jp.svg';
import IconUS from 'icons/misc/us.svg';
import styles from './LanguageSwitcher.module.scss';

const StoreSelectorModal = dynamic(
  () => import('components/storeSelectorModal/StoreSelectorModal'),
  {
    ssr: false,
  }
);

/**
 * LanguageSwitcher Component - used to change selected locale.
 */
export const LanguageSwitcher = () => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  // Toggle Active Header
  const { toggle: toggleOverlay } = useContext(OverlayContext);
  const flagGenerator = (locale: string) => {
    switch (locale) {
      case 'en-US':
        return <IconUS />;
      case 'en-GB':
        return <IconGB />;
      case 'en-CH':
        return <IconCH />;
      case 'de-CH':
        return <IconCH />;
      case 'fr-CH':
        return <IconCH />;
      case 'it-CH':
        return <IconCH />;
      case 'de-DE':
        return <IconDE />;
      case 'it-IT':
        return <IconIT />;
      case 'fr-FR':
        return <IconFR />;
      case 'es-ES':
        return <IconES />;
      case 'jp-JP':
        return <IconJP />;
      default:
        return <IconUS />;
    }
  };

  return (
    <div className={styles.languageSwitcher}>
      <button
        className={styles.languageSwitcher__button}
        onClick={() => {
          setShowModal(true);
          toggleOverlay({ namespace: 'storeSelector' });
        }}
        type="button"
        aria-label={i18n._(
          /* i18n */ {
            id: 'storeSelector.open',
            message: 'Open Store Selector Modal',
          }
        )}
        data-test-id="languageSwitcher"
      >
        {flagGenerator(router.locale || 'en-GB')}
      </button>
      {showModal && <StoreSelectorModal />}
    </div>
  );
};
